import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import SlickSlider from 'react-slick';
import breakpoints from 'utils/breakpoints';
import Heading from 'components/Heading';
import Quote from './Quote';
import * as QuoteImgs from './QuoteImgs';

const IMAGES = [<QuoteImgs.HoaHoang />, <QuoteImgs.HuongLuu />];

const Slider = styled(SlickSlider)`
  margin-bottom: 66px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 120px;
  }

  .slick-dots {
    text-align: left;

    li {
      margin: 0;

      button:before {
        color: #d8d8d8;
        opacity: 1;
      }

      &.slick-active button:before {
        color: #0276f1;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 50vw;

  .lazy-load-image-background,
  .lazy-load-image-background img {
    height: 100%;
    display: block !important;
  }

  @media (min-width: ${breakpoints.md}) {
    height: 640px;
  }
`;

function ExpertQuotes() {
  const [index, setIndex] = useState(0);

  return (
    <Container>
      <Row align="end">
        <Col md={5} offset={{ md: 1 }}>
          <Row>
            <Col md={10}>
              <Heading
                name="TrustingSocial Power"
                title="Opportunity to work with experts."
              />
            </Col>
          </Row>
          <Slider fade dots afterChange={i => setIndex(i)}>
            <Quote
              author={{
                name: 'Hoa Hoang',
                title: 'Software Engineer - Telco team',
                company: 'Trusting Social Vietnam',
              }}
              content="Working at Trusting Social has been an amazing journey for me so far. The thing I appreciate most about the company is that it has allowed me to work in a pleasant multicultural environment with very nice and talented colleagues. I am proud to be a part of Trusting Social."
            />
            <Quote
              author={{
                name: 'Huong Luu',
                title: 'Sales / Account Manager',
                company: 'Trusting Social Vietnam',
              }}
              content="I have a PhD in Computer Science and joined Trusting Social originally as a Data Analyst, but transferred into our Sales team a while back. Trusting Social continues to push your boundaries and encourages you to leave your comfort zone - the company will nurture your talents and help you to discover skills you didn’t know you had - it is an exciting journey!"
            />
          </Slider>
        </Col>
        <Col md={4} offset={{ md: 1 }}>
          <ImageWrapper>{IMAGES[index]}</ImageWrapper>
        </Col>
      </Row>
    </Container>
  );
}

export default ExpertQuotes;
