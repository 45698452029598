/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Button from 'components/Button';
import breakpoints from 'utils/breakpoints';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { ReactComponent as DepartmentIcon } from 'assets/icons/department.svg';
import { ReactComponent as BriefcaseIcon } from 'assets/icons/briefcase.svg';
import Select from 'react-select';
import { Row, Col } from 'react-grid-system';

function getLocationName(location) {
  if (!location) {
    return '';
  }

  return [location.city, location.country]
    .filter(Boolean)
    .join(', ')
    .trim();
}

const countriesPriority = [
  'vietnam',
  'india',
  'indonesia',
  'philippines',
  'singapore',
];
const citiesPriority = [
  ['ho chi minh', 'hanoi', 'bengaluru', 'pune', 'jakarta', 'manila'],
];

function compareLocationName(locationNameA, locationNameB) {
  if (!locationNameA && !locationNameB) {
    return 0;
  }

  const lowerA = (locationNameA || '').toLowerCase().trim();
  const lowerB = (locationNameB || '').toLowerCase().trim();

  const priorityCountryA = countriesPriority.findIndex(country =>
    lowerA.includes(country)
  );
  const priorityCountryB = countriesPriority.findIndex(country =>
    lowerB.includes(country)
  );

  if (priorityCountryA === -1 && priorityCountryB === -1) {
    return 0;
  }

  if (priorityCountryA !== priorityCountryB) {
    return priorityCountryA > priorityCountryB ? 1 : -1;
  }

  const priorityCityA = citiesPriority.findIndex(city => lowerA.includes(city));
  const priorityCityB = citiesPriority.findIndex(city => lowerB.includes(city));

  if (priorityCityA === priorityCityB) {
    return 0;
  }
  return priorityCityA > priorityCityB ? 1 : -1;
}

const ITEMS_PER_PAGE = 10;

const Table = styled.table`
  margin-top: 22px;
  border-collapse: collapse;
  width: 100%;

  th {
    padding: 5px 0;
    font-family: Helvetica;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 0;
    line-height: 1.875rem;
    text-transform: uppercase;
  }

  td {
    padding: 23px 0;
    text-transform: capitalize;
    position: relative;

    &:first-child::before,
    &:last-child::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      width: 0;
      height: 100%;
      transition: 0.3s;
    }

    &:first-child::before {
      left: 0;
      border-radius: 5px 0 0 5px;
    }

    &:last-child::before {
      right: 0;
      border-radius: 0 5px 5px 0;
    }
  }

  th,
  td {
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &:last-child {
      text-align: right;
    }
  }
`;

const BodyRow = styled.tr`
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.15);

    td {
      color: #1c2f45;
      background: #ffffff;

      &:first-child::before,
      &:last-child::before {
        width: 1rem;
        background: #ffffff;
      }

      &:first-child::before {
        left: -1rem;
      }

      &:last-child::before {
        right: -1rem;
      }

      @media (min-width: ${breakpoints.md}) {
        &:first-child::before,
        &:last-child::before {
          width: 23px;
          background: #ffffff;
        }

        &:first-child::before {
          left: -23px;
        }

        &:last-child::before {
          right: -23px;
        }
      }
    }

    svg {
      color: rgba(28, 47, 69, 0.3);
      transition: 0.3s;
    }
  }
`;

const Title = styled.td`
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.875rem;
  transition: 0.3s;
  max-width: 140px;

  @media (min-width: ${breakpoints.md}) {
    max-width: none;
  }
`;

const Value = styled.td`
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.875rem;
  transition: 0.3s;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};

  svg {
    color: rgba(255, 255, 255, 0.3);
    margin-right: 10px;
  }

  span {
    line-height: 1.2rem;
    font-size: 0.8rem;

    @media (min-width: ${breakpoints.md}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

const JobTypeContent = styled(Content)`
  width: 100px;
`;

const LoadMore = styled(Button)`
  margin-top: 50px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  padding-left: 35px;
  padding-right: 35px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 80px;
  }
`;

const StyledSearchInput = styled.input`
  width: 100%;
  line-height: 1.7;
  padding: 0 35px;
`;

const StyledSelect = styled(Select)`
  text-align: left;
  width: 100%;
  color: fieldtext;

  .select__control {
    height: 40px;
    border-radius: 0;
  }
  .select__value-container {
    line-height: 1.7;
  }
  .select__option--is-selected {
    background-color: #1c2f45;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  margin-bottom: 1rem;

  & .search-icon {
    position: absolute;
    top: 12px;
    left: 8px;
    width: 18px;
  }
`;

const StyledRow = styled(Row)`
  row-gap: 1rem;
`;

function JobList({ jobs }) {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [team, setTeam] = useState('');
  const [location, setLocation] = useState('');
  const transformedJobs = useMemo(
    () =>
      jobs.map(job => ({
        ...job,
        locationName: getLocationName(job.location),
      })),
    [jobs]
  );

  const teamOptions = useMemo(() => {
    const options = transformedJobs.reduce((prev, job) => {
      if (!job.team || prev.find(option => job.team === option.value)) {
        return prev;
      }
      return [...prev, { value: job.team, label: job.team }];
    }, []);

    return options.sort(({ value: teamA }, { value: teamB }) => {
      const lowerA = teamA.toLowerCase();
      const lowerB = teamB.toLowerCase();

      if (lowerA < lowerB) {
        return -1;
      }
      if (lowerA > lowerB) {
        return 1;
      }
      return 0;
    });
  }, [transformedJobs]);

  const locationOptions = useMemo(() => {
    const options = transformedJobs.reduce((prev, job) => {
      if (
        !job.locationName ||
        prev.find(option => job.locationName === option.value)
      ) {
        return prev;
      }
      return [...prev, { value: job.locationName, label: job.locationName }];
    }, []);
    return options.sort(({ value: locationNameA }, { value: locationNameB }) =>
      compareLocationName(locationNameA, locationNameB)
    );
  }, [transformedJobs]);

  const filteredJobs = useMemo(
    () =>
      transformedJobs.filter(job => {
        const keywordCondition = keyword
          ? job.title && job.title.toLowerCase().includes(keyword.toLowerCase())
          : true;

        const teamCondition = team ? job.team && job.team === team : true;
        const locationCondition = location
          ? job.locationName === location
          : true;

        return keywordCondition && teamCondition && locationCondition;
      }),
    [transformedJobs, keyword, location, team]
  );

  const totalPage = Math.ceil(filteredJobs.length / ITEMS_PER_PAGE);
  const hasMore = page < totalPage;

  return (
    <>
      <Wrapper>
        <img
          className="search-icon"
          src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwMDAwMCIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
        />
        <StyledSearchInput
          value={keyword}
          onChange={e => {
            setKeyword(e.target.value);
          }}
          placeholder="Search Your Job"
        />
      </Wrapper>

      <StyledRow gutterWidth={8}>
        <Col xs={12} md={6}>
          <StyledSelect
            classNamePrefix="select"
            placeholder="Select location"
            isClearable
            options={locationOptions}
            onChange={value => {
              if (value) {
                setLocation(value.value);
                return;
              }
              setLocation('');
            }}
          />
        </Col>

        <Col xs={12} md={6}>
          <StyledSelect
            classNamePrefix="select"
            placeholder="Select department"
            isClearable
            options={teamOptions}
            onChange={value => {
              if (value) {
                setTeam(value.value);
                return;
              }
              setTeam('');
            }}
          />
        </Col>
      </StyledRow>

      <Table>
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Department</th>
            <th>Location</th>
            <th>Job Type</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs.slice(0, page * ITEMS_PER_PAGE).map(job => (
            <BodyRow
              key={job.rawID}
              onClick={() => navigate(`/careers/openings/${job.slug}`)}
            >
              <Title>{job.title}</Title>
              <Value>
                {job.team && (
                  <Content>
                    <DepartmentIcon />
                    <span>{job.team}</span>
                  </Content>
                )}
              </Value>
              <Value>
                <Content>
                  <PinIcon />
                  <span>{job.locationName}</span>
                </Content>
              </Value>
              <Value>
                <JobTypeContent align="flex-end">
                  <BriefcaseIcon />
                  <span>{job.position_type}</span>
                </JobTypeContent>
              </Value>
            </BodyRow>
          ))}
        </tbody>
      </Table>
      {hasMore && (
        <LoadMore onClick={() => setPage(page + 1)}>Load more</LoadMore>
      )}
    </>
  );
}

JobList.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      rawID: PropTypes.string,
      title: PropTypes.string,
      team: PropTypes.string,
      position_type: PropTypes.string,
      location: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
      }),
    })
  ),
};

JobList.defaultProps = {
  jobs: [],
};

export default JobList;
