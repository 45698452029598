import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { light, dark } from 'themes';
import breakpoints from 'utils/breakpoints';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import { HEADER_HEIGHT } from 'components/Header';
import ValueList from 'components/Careers/ValueList';
import JobList from 'components/Careers/JobList';
import ExpertQuotes from 'components/Careers/ExpertQuotes';
import { ReactComponent as IntroBg } from 'assets/careers/intro-bg.svg';

const Intro = styled.section`
  padding-top: ${HEADER_HEIGHT + 42}px;
  background-image: linear-gradient(180deg, #ddeeff 0%, #ffffff 99%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${HEADER_HEIGHT + 55}px;
    position: relative;
    height: 68vh;
  }
`;

const IntroContainer = styled(Container)`
  z-index: 4;
`;

const CoreValues = styled.section`
  background: #fff;
  padding-bottom: 20px;

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 30px;
  }
`;

const CareerOpportunities = styled.section`
  padding: 0 0 15px 0;
  background: #0276f1;
  color: #ffffff;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding: 10px 0 24px;
  }
`;

const TSPower = styled.section`
  padding: 66px 0 0 0;
  background: #f9f9f9;

  @media (min-width: ${breakpoints.md}) {
    padding: 152px 0 0 0;
  }
`;

const IntroImage = styled(IntroBg)`
  max-width: 100%;
  max-height: 50vw;

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

function Careers({ data }) {
  const {
    allRecruiterboxOpening: { nodes: openings },
  } = data;

  return (
    <ThemeProvider theme={light}>
      <Layout fullSize pageTitle="Careers">
        <ThemeProvider theme={dark}>
          <Intro>
            <IntroContainer>
              <Row>
                <Col md={8} offset={{ md: 2 }}>
                  <Heading title="Join our rocketship and have a positive impact on people's lives" />
                </Col>
              </Row>
            </IntroContainer>
            <IntroImage />
          </Intro>
          <CoreValues>
            <Container>
              <ValueList />
            </Container>
          </CoreValues>
          <CareerOpportunities id="openPositions">
            <Container>
              <Row>
                <Col>
                  <ThemeProvider theme={light}>
                    <Heading title="Career Opportunities" />
                  </ThemeProvider>
                </Col>
              </Row>
              <Row justify="center">
                <Col lg={9}>
                  <JobList jobs={openings} />
                </Col>
              </Row>
            </Container>
          </CareerOpportunities>
          <TSPower>
            <ExpertQuotes />
          </TSPower>
        </ThemeProvider>
      </Layout>
    </ThemeProvider>
  );
}

export default Careers;

Careers.propTypes = {
  data: PropTypes.shape({
    allRecruiterboxOpening: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
          rawID: PropTypes.string,
          title: PropTypes.string,
          created_date: PropTypes.number,
          modified_date: PropTypes.number,
          team: PropTypes.string,
          state: PropTypes.string,
          position_type: PropTypes.string,
          location: PropTypes.shape({
            city: PropTypes.string,
            state: PropTypes.string,
            country: PropTypes.string,
          }),
        })
      ),
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query {
    allRecruiterboxOpening {
      nodes {
        rawID
        slug
        title
        state
        created_date
        position_type
        created_date
        modified_date
        team
        location {
          city
          state
          country
        }
      }
    }
  }
`;
