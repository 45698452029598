import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';
import Card from 'components/Card';
import { ReactComponent as BenefitsIcon } from 'assets/careers/benefits.svg';
import { ReactComponent as ChallengeIcon } from 'assets/careers/challenge.svg';
import { ReactComponent as TopOneIcon } from 'assets/careers/top1.svg';
import Paragraph from 'components/Paragraph';

const StyledRow = styled(Row)`
  margin: 0;
  background: #fff;
`;

const ValueItem = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 0;
  height: 100%;
  margin-bottom: 30px;

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem 0;
    margin-bottom: 0;
  }
`;

const StyledCard = styled(Card)`
  align-items: center;
  background: none;
  padding: 0 3.56rem;
  text-align: center;
`;

const CardText = styled(Paragraph)`
  margin: 0;
`;

function ValueList() {
  return (
    <StyledRow>
      <Col md={6} lg={4}>
        <ValueItem>
          <StyledCard icon={<BenefitsIcon />} title="Great Benefits ">
            <CardText>
              We offer world-class benefits to our employees, and also make sure
              that we support everybody&apos;s work-life balance
            </CardText>
          </StyledCard>
        </ValueItem>
      </Col>
      <Col md={6} lg={4}>
        <ValueItem>
          <StyledCard icon={<TopOneIcon />} title="We only hire the top 1%">
            <CardText>
              Our recruitment process is designed to make sure we only hire top
              talent - you will be working with some of the smartest heads in
              the industry
            </CardText>
          </StyledCard>
        </ValueItem>
      </Col>
      <Col md={6} lg={4}>
        <ValueItem>
          <StyledCard icon={<ChallengeIcon />} title="Fascinating Challenge">
            <CardText>
              Trusting Social encourages your career development & at the same
              time, you get to make an impact on people´s lives
            </CardText>
          </StyledCard>
        </ValueItem>
      </Col>
    </StyledRow>
  );
}

export default ValueList;
